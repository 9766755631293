import request from "@/utils/request";

export function getQuestion() {
    return request({
        url: "/exam-by-student",
        method: "post",
    });
}

export function submitAnswer(data) {
    return request({
        url: "/submit-answer",
        method: "post",
        data,
    });
}

export function getScore(data) {
    return request({
        url: "/calculate-score",
        method: "post",
        data,
    });
}

export function getTotalQuestion() {
    return request({
        url: "/total-question",
        method: "post",
    });
}

export function getForms(params) {
    return request({
        url: "/forms",
        method: "get",
        params,
    });
}
export function getForm(id) {
    return request({
        url: "/forms/" + id,
        method: "get",
    });
}
